import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import Layout from '../../../components/Layout';
import { getRequestTypeName, __ } from '../../../utilities';
import { loadRequestDetail } from './actions';
import DSGEditManager from './DSGEditManager';
import PoDataChangeManager from './PoDataChangeManager';
import PoRegistryManager from './PoRegistryManager';
import {
  requestDetailIsFetchingSelector,
  requestDetailSelector,
} from './selectors';
import SportExpertOrgManager from './SportExpertOrgManager';
import SportOrgManager from './SportOrgManager';
import AdminNote from './Universal/AdminNote';
import CommonRequestInfo from './Universal/CommonRequestInfo';
import Po from './Universal/Po';
import Ppo from './Universal/Ppo';
import PoChange from './Universal/PoChange';
import SportExpertOrg from './Universal/SportExpertOrg';
import SportOrg from './Universal/SportOrg';
import StatusLog from './Universal/StatusLog';
import LicenseAttachments from './Universal/LicenseAttachments';
import UserLicense from './Universal/UserLicense';
import UserNote from './Universal/UserNote';
import UserProfile from './Universal/UserProfile';
import UserTransfer from './Universal/UserTransfer';
import UserProfileChangeManager from './UserProfileChangeManager';
import TransferManager from './TransferManager';
import Workflow from './workflow';
import LicenseManager from './LicenseManager';
import PpoRegistrationManager from './PpoRegistrationManager';
import ParentPpo from './Universal/ParentPpo';

const RequestDetail = ({
  isFetching,
  detail,
  id,
  appSpace,
  loadRequestDetail,
}) => {
  React.useEffect(() => {
    (async () => {
      await loadRequestDetail(appSpace, id);
    })();
  }, [loadRequestDetail, appSpace, id]);

  let StatusManager = null;
  switch (detail.request_type) {
    case 'sport-org':
      StatusManager = <SportOrgManager request={detail} />;
      break;
    case 'sport-expert-org':
      StatusManager = <SportExpertOrgManager request={detail} />;
      break;
    case 'po-registry':
      StatusManager = <PoRegistryManager request={detail} />;
      break;
    case 'user-profile-change':
      StatusManager = <UserProfileChangeManager request={detail} />;
      break;
    case 'po-data-change':
      StatusManager = <PoDataChangeManager request={detail} />;
      break;
    case 'dsg-edit':
      StatusManager = <DSGEditManager request={detail} />;
      break;
    case 'person-transfer-permanent':
    case 'person-transfer-limited':
      StatusManager = <TransferManager request={detail} />;
      break;
    case 'license':
      StatusManager = <LicenseManager request={detail} />;
      break;
    case 'ppo-registration':
      StatusManager = <PpoRegistrationManager request={detail} />;
      break;
    default:
      break;
  }

  return (
    <Layout
      isFetching={isFetching}
      bottomFixed={
        <ContextBar>
          <ContextBarItem></ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        backUrl={`/admin/${appSpace}/requests`}
        title={getRequestTypeName(detail.request_type)}
      />
      <Segment>
        {!!detail.workflow &&
          ((detail.workflow.transitions || []).length > 0 ||
            !!detail.workflow.title) && (
            <Segment raised>
              <Workflow workflow={detail.workflow} />
            </Segment>
          )}
        <CommonRequestInfo request={detail} appSpace={appSpace} />
        <Segment raised>
          <Row>
            <Col s={12} m={6}>
              <Header size="s">{__('Manažment žiadosti')}</Header>
              {StatusManager}
            </Col>
          </Row>
        </Segment>

        <Po request={detail} />

        <Ppo request={detail} />

        <ParentPpo request={detail} />

        {detail.request_type === 'po-data-change' && (
          <PoChange request={detail} />
        )}

        <SportOrg request={detail} />

        <SportExpertOrg request={detail} />

        <AdminNote request={detail} />

        <UserLicense request={detail} />

        <UserProfile request={detail} />

        <UserTransfer request={detail} />

        <UserNote request={detail} />

        <StatusLog request={detail} />

        {detail.request_type === 'license' && (
          <LicenseAttachments request={detail} />
        )}
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { appspace: appSpace, id },
    },
  } = ownProps;
  return {
    isFetching: requestDetailIsFetchingSelector(state),
    detail: requestDetailSelector(state),
    id,
    appSpace,
  };
};

const mapDispatchToProps = {
  loadRequestDetail: loadRequestDetail,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(withTheme(RequestDetail));
