import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';

const CookiesForm = () => {
  return (
    <Row>
      <Col>
        <Field
          component={FormField}
          name="cookies.additionalText"
          type="textarea"
          label={__('Doplnkový text (nepovinné)')}
          info={__(
            'Zobrazí sa pod textom "Použitie cookies" v rámci nastavení cookies na verejných stránkach.',
          )}
        />
      </Col>
    </Row>
  );
};

export default CookiesForm;
