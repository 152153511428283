import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { formatToString, getProp, __ } from '../../../../../utilities';
import {
  codelistEntrySelector,
  countryCodelistSelector,
} from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';
import { universalRequestDefinition } from '../../../definitions';
import Message from '@sportnet/ui/Message';

// clean value
const clv = (val, field = null) => {
  return val && (val[field] || JSON.stringify(val));
};

class Po extends PureComponent {
  render() {
    const {
      request: { status, data: { po } = {} },
      legalForm,
      countries,
    } = this.props;
    if (!po) return null;
    const { organization = {}, targetGroups = [], ...restPoData } = po;

    const getCountry = countryCode => {
      const item = countries.find(country => {
        return country.value === countryCode;
      });
      return getProp(item, ['label']);
    };

    const getSportExpert = expert => {
      if (expert) {
        return formatToString(
          clv(expert, 'display_name'),
          clv(expert, 'license_type'),
          `licencia č. ${clv(restPoData.sport_expert_1, 'license_id')}`,
        );
      }
      return '';
    };

    const getUser = expert => {
      if (expert) {
        return [
          <>
            {clv(expert, 'name')},{' '}
            <a href={`mailto:${clv(expert, 'email')}`}>
              {clv(expert, 'email')}
            </a>
          </>,
          formatToString(
            clv(expert, 'license_type'),
            `licencia č. ${clv(expert, 'license_id')}`,
          ),
          `Veľkosť oblečenia: ${clv(expert, 'shirt_size')}`,
        ].map(e => <div>{e}</div>);
      }
      return '';
    };

    // TODO: vycitat z ciselnika a presunut
    const getAddressType = type => {
      switch (type) {
        case 'HOME':
          return __('Bydlisko');
        case 'POSTAL':
          return __('Poštová');
        case 'INVOICE':
          return __('Fakturačná');
        default:
          return type;
      }
    };

    const getAddressStatus = status => {
      switch (status) {
        case 'active':
          return __('Aktívna');
        case 'inactive':
          return __('Neaktívna');
        default:
          return status;
      }
    };

    // TODO: presunut do spolocneho suboru (podobne pre user profile)
    const getKVAddresses = addresses => {
      if (addresses) {
        return addresses.map(address => {
          return {
            key: `${getAddressType(address.type)} adresa`,
            values: [
              `${address.street} ${address.number}, ${address.zip} ${address.city}`,
              getAddressStatus(address.status),
            ],
          };
        });
      }
      return [];
    };
    const kvFields = [
      {
        key: __('Názov spoločnosti'),
        values: [organization.full_name || ''],
      },
      {
        key: __('Skrátený názov'),
        values: [organization.name || ''],
      },
      {
        key: __('IČO'),
        values: [organization.business_id || ''],
      },
      {
        key: __('DIČ/IČDPH'),
        values: [organization.vat_nr || ''],
      },
      {
        key: __('Právna forma'),
        values: [legalForm.label || legalForm.value || ''],
      },
      {
        key: __('Štatutárny zástupca'),
        values: [
          formatToString(
            organization.contact_person,
            organization.email,
            organization.phone,
          ),
        ],
      },
      {
        key: __('Bankové spojenie'),
        values: [clv(restPoData.bank_account, 'IBAN')],
      },

      {
        key: __('Kontaktná osoba'),
        values: [
          [
            formatToString(
              clv(restPoData.contact_person_2, 'display_name'),
              restPoData.contact_person_2.phone
                ? clv(restPoData.contact_person_2, 'phone')
                : undefined,
            ),
            restPoData.contact_person_2.email ? (
              <a href={`mailto:${clv(restPoData.contact_person_2, 'email')}`}>
                ,{clv(restPoData.contact_person_2, 'email')}
              </a>
            ) : undefined,
          ],
        ],
      },
    ];

    if (restPoData.sport_expert_1) {
      kvFields.push({
        key: __('Športový expert 1'),
        values: [getSportExpert(restPoData.sport_expert_1)],
      });
    }
    if (restPoData.sport_expert_2) {
      kvFields.push({
        key: __('Športový expert 2'),
        values: [getSportExpert(restPoData.sport_expert_2)],
      });
    }
    if ((restPoData.users || []).length) {
      restPoData.users.forEach((u, uIdx) => {
        kvFields.push({
          key: `${__('Športový expert')} ${uIdx + 1}`,
          values: [getUser(u)],
        });
      });
    }

    if (restPoData.address) {
      kvFields.push({
        key: __('Adresa'),
        values: [
          formatToString(
            `${restPoData.address.street} ${restPoData.address.number}`,
            `${restPoData.address.zip} ${restPoData.address.city}`,
            getCountry(restPoData.address.country),
          ),
        ],
      });
    }

    if (restPoData.addresses) {
      kvFields.push(...getKVAddresses(restPoData.addresses));
    }

    return (
      <React.Fragment>
        <Segment raised>
          {organization.source === 'fins' && status === 'APPROVED' && (
            <Message warning>
              {__(
                'Táto PO zatiaľ neexistuje v systéme. Pri potvrdení bude vytvorená.',
              )}
            </Message>
          )}
          <Row>
            <Col m={6}>
              <Header size="s">{__('Právnická osoba')}</Header>
              <KeyValueTable rows={kvFields} />
            </Col>
          </Row>
        </Segment>
        {targetGroups.length > 0 && (
          <Segment raised>
            <Row>
              <Col m={6}>
                <Header size="s">{__('Cieľové skupiny')}</Header>
                <KeyValueTable
                  rows={targetGroups
                    .filter(i => !!i)
                    .map(i => {
                      const label =
                        i.targetGroupIdx === 0
                          ? __('Materská škola')
                          : __('Základná škola');
                      return {
                        key: label,
                        values: [`${__('Počeť detí:')} ${i.children_count}`],
                      };
                    })}
                />
              </Col>
            </Row>
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

Po.propTypes = {
  request: universalRequestDefinition.isRequired,
  legalForm: codelistDefinition.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    request: { data: { po = {} } = {} },
  } = props;
  const poLegalForm = getProp(po, ['organization', 'legal_form'], '');
  return {
    legalForm: codelistEntrySelector('legal-form', poLegalForm)(state),
    countries: countryCodelistSelector(state),
  };
};

export default connect(mapStateToProps)(Po);
