import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import { fetchActiveUser, setActiveFO } from './actions';
import { matchDefinition } from './definitions';
import AddressesList from './detail/addresses/list';
import PhotosList from './detail/photos/list';
import AffiliationsList from './detail/affiliations/list';
import AthletesList from './detail/athletes/list';
import AwardsList from './detail/awards/list';
import BankAccountsList from './detail/bankAccounts/list';
import BiosList from './detail/bios/list';
import CustomList from './detail/custom/list';
import ExpertsList from './detail/experts/list';
import LicencesList from './detail/licences/list';
import DocumentsList from './detail/documents/list';
import MedicalExaminationsList from './detail/medicalExaminations/list';
import MembershipList from './detail/membership/list';
import ProfileDetail from './detail/profile/detail';
import RegnrsList from './detail/regnrs/list';
import TransfersList from './detail/transfers/list';
import { getActiveUserIsFetchingSelector } from './selectors';
import { em } from 'polished';

export const Faded = styled.span`
  font-size: ${em(12)};
  color: ${({ theme }) => theme.color.fadedText};
`;

class FODetail extends PureComponent {
  componentDidMount() {
    const { sportnetId } = this.props.match.params;
    this.props.dispatch(setActiveFO(sportnetId));
    this.props.dispatch(
      fetchActiveUser(this.props.match.params.appspace, sportnetId),
    );
  }

  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/profile"
          component={ProfileDetail}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/addresses"
          component={AddressesList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/bank-accounts"
          component={BankAccountsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/photos"
          component={PhotosList}
        />
        <Route
          path="/admin/:appspace/users/:sportnetId/affiliations"
          exact
          component={AffiliationsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/athletes"
          component={AthletesList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/experts"
          component={ExpertsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/awards"
          component={AwardsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/licences"
          component={LicencesList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/documents"
          component={DocumentsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/membership"
          component={MembershipList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/regnrs"
          component={RegnrsList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/transfers"
          component={TransfersList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/bios"
          component={BiosList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/custom"
          component={CustomList}
        />
        <Route
          exact
          path="/admin/:appspace/users/:sportnetId/medical-examinations"
          component={MedicalExaminationsList}
        />
      </React.Fragment>
    );
  }
}

FODetail.propTypes = {
  match: matchDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  appspace: appspaceDefinition.isRequired,
};

FODetail.defaultProps = {};

const mapStateToProps = state => {
  return {
    isFetching: getActiveUserIsFetchingSelector(state),
    appspace: appspaceSelector(state),
  };
};

export default connect(mapStateToProps)(withRouter(FODetail));
