import React from 'react';
import Segment from '@sportnet/ui/Segment';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { __, getProp } from '../../../../../utilities';
import { countryCodelistSelector } from '../../../../App/selectors';
import { connect } from 'react-redux';
import BasicTable from '@sportnet/ui/BasicTable';
import SectorLabel from '../../../../../containers/SectorLabel';
import styled from 'styled-components';
import { rem } from 'polished';

const Space = styled.div`
  height: ${rem(32)};
`;

const Ppo = ({ request: { data: { ppo } = {} }, countries }) => {
  if (!ppo) return null;

  const getCountry = countryCode => {
    const item = countries.find(country => {
      return country.value === countryCode;
    });
    return getProp(item, ['label']);
  };

  const kvFields = [
    { key: __('Názov'), values: [ppo.name] },
    { key: __('ID'), values: [ppo._id] },
    { key: __('Kontaktná osoba'), values: [ppo.contactPerson] },
    { key: __('Krajina'), values: [getCountry(ppo.country)] },
    { key: __('Ulica'), values: [ppo.street] },
    { key: __('Číslo'), values: [ppo.number] },
    { key: __('Mesto'), values: [ppo.city] },
    { key: __('PSČ'), values: [ppo.postCode] },
    { key: __('Email'), values: [ppo.email] },
    { key: __('Web'), values: [ppo.web] },
    { key: __('Telefón'), values: [ppo.phone] },
    { key: __('Fax'), values: [ppo.fax] },
  ];

  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">{__('Profil právnickej osoby')}</Header>
          <KeyValueTable rows={kvFields} />
        </Col>
        <Col xs={12}>
          <Space />
          <Header size="s">{__('Sektory')}</Header>
          <BasicTable
            columns={[
              {
                id: 'value',
                header: __('Sektor'),
              },
            ]}
            rows={ppo.sectors}
            renderRow={item => {
              return [
                <>
                  <SectorLabel sector={item} />
                </>,
              ];
            }}
            rowKey="_id"
          />
        </Col>
      </Row>
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    countries: countryCodelistSelector(state),
  };
};

export default connect(mapStateToProps)(Ppo);
