import React from 'react';
import StatusManager from '../Universal/StatusManager';
import { getRequestStatusName, __ } from '../../../../utilities';
import Api from '../../../../Api';
import getProp from '@sportnet/utilities/getProp';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { receiveRequestDetailSuccess } from '../actions';
import { Loading } from '../../../../components/Loader';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import ReadOnlyTextArea from '../../../../components/ReadOnlyTextArea';
import Icon from '@sportnet/ui/Icon';

const PpoRegistrationManager = ({ request, dispatch }) => {
  const { appspace } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [concernedOrganizations, setConcernedOrganizations] = React.useState(
    [],
  );
  const [
    isFetchingConcernedOrganizations,
    setIsFetchingConcernedOrganizations,
  ] = React.useState(false);

  const getConcernedOrganizations = async request => {
    setIsFetchingConcernedOrganizations(true);

    try {
      const promises = [];
      request.confirmedBy.forEach(confirmation => {
        promises.push(Api.organizationPPOProfile(confirmation.appSpace));
      });
      const organizations = await Promise.all(promises);
      setConcernedOrganizations(organizations);
    } catch (e) {
      //
    } finally {
      setIsFetchingConcernedOrganizations(false);
    }
  };

  const changeStatus = async status => {
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')} ${__(
      'z',
    )} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      setLoading(true);

      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}${noteText}`);

        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;

        newRequest = await Api.crmConfirmPpoRegistrationRequest(
          appspace,
          request._id,
          {
            data: {},
          },
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }

      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'XX_ERROR') {
        // eslint-disable-next-line
        window.alert(__('XX_ERROR'));
      } else {
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    changeStatus('CANCELLED');
  };

  const handleNew = () => {
    changeStatus('NEW');
  };

  const handleReject = () => {
    changeStatus('REJECTED');
  };

  const handleConfirm = () => {
    changeStatus('CONFIRMED');
  };

  const handleClose = () => {
    changeStatus('CLOSED');
  };

  React.useEffect(() => {
    getConcernedOrganizations(request);
  }, [request]);

  if (isFetchingConcernedOrganizations) {
    return <Loading size="xl" />;
  }

  return (
    <React.Fragment>
      <StatusManager
        request={request}
        loading={loading}
        onCancel={handleCancel}
        onNew={handleNew}
        onReject={handleReject}
        onConfirm={handleConfirm}
        onClosed={handleClose}
      />

      {concernedOrganizations.length > 0 && (
        <FormGroup>
          <Label>{__('Potvrdzujúce organizácie: ')}</Label>
          {concernedOrganizations.map(org => (
            <ReadOnlyTextArea key={org._id}>
              {org.name}{' '}
              <Icon
                size="s"
                name={
                  (
                    request.confirmedBy.find(
                      ({ appSpace }) => appSpace === org._id,
                    ) || {}
                  ).confirmed
                    ? 'check'
                    : 'visibility'
                }
              />
            </ReadOnlyTextArea>
          ))}
        </FormGroup>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(PpoRegistrationManager);
