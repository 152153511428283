import React from 'react';
import Segment from '@sportnet/ui/Segment';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { __, getProp } from '../../../../../utilities';
import { countryCodelistSelector } from '../../../../App/selectors';
import { connect } from 'react-redux';
import API from '../../../../../Api';

const Ppo = ({ request: { data: { parentPpo } = {} }, countries }) => {
  const [ppo, setPpo] = React.useState();

  React.useEffect(() => {
    (async () => {
      if (parentPpo?._id) {
        const response = await API.organizationPPOProfile(parentPpo._id);
        setPpo(response);
      }
    })();
  }, [parentPpo]);

  if (!parentPpo) return null;

  const getCountry = countryCode => {
    const item = countries.find(country => {
      return country.value === countryCode;
    });
    return getProp(item, ['label']);
  };

  const kvFields = [
    { key: __('Názov'), values: [ppo?.name] },
    { key: __('ID'), values: [ppo?._id] },
    { key: __('Kontaktná osoba'), values: [ppo?.contactPerson] },
    { key: __('Krajina'), values: [getCountry(ppo?.country)] },
    { key: __('Ulica'), values: [ppo?.street] },
    { key: __('Číslo'), values: [ppo?.number] },
    { key: __('Mesto'), values: [ppo?.city] },
    { key: __('PSČ'), values: [ppo?.postCode] },
    { key: __('Email'), values: [ppo?.email] },
    { key: __('Web'), values: [ppo?.web] },
    { key: __('Telefón'), values: [ppo?.phone] },
    { key: __('Fax'), values: [ppo?.fax] },
  ];

  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">{__('Profil nadradenej právnickej osoby')}</Header>
          <KeyValueTable rows={kvFields} />
        </Col>
      </Row>
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    countries: countryCodelistSelector(state),
  };
};

export default connect(mapStateToProps)(Ppo);
