export default {
  DATE_FORMAT: 'dd.MM.yyyy',
  DATE_FORMAT_WITH_TIME: 'dd.MM.yyyy HH:mm',
  TRANSFER_TYPE: {
    H: 'Hosťovanie',
    HX: 'Ukončenie hosťovania',
    S: 'Striedavý štart',
    ZSK: 'Zapožičanie na Slovensko',
    ZW: 'Zapožičanie do zahraničia',
    P: 'Prestup',
    PX: 'Opakovaný prestup',
    PSKN: 'Prestup na Slovensko normálny',
    PSKP: 'Prestup na Slovensko provizórny',
    PWN: 'Prestup do zahraničia normálny',
    PWP: 'Prestup do zahraničia provizórny',
    PSO: 'Prestup s obmedzením',
    DR: 'Dočasná registrácia',
  },
};
