import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Form, reduxForm } from 'redux-form';
import PhotoUpload from '../../../components/PhotoUpload/redux-form';
import { __ } from '../../../utilities';
import ContactInfoForm from '../ContactInfo/form';
import GDPRForm from '../GDPR/form';
import ThemeForm from '../Theme/form';
import Col, { Row } from '@sportnet/ui/Grid';
import CookiesForm from '../Cookies/form';

export const FORM_NAME = 'COMMON_FORM';

class CommonForm extends React.PureComponent {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <Segment raised>
          <Header size="xs">{__('Kontaktné údaje')}</Header>
          <ContactInfoForm />
        </Segment>
        <Row>
          <Col m="6" s="12">
            <Segment raised>
              <Header size="xs">{__('Logo')}</Header>
              <PhotoUpload
                formName={FORM_NAME}
                uploadFieldName="logoUpload"
                deleteFieldName="logoDelete"
                src={this.props.profileLogoSrc}
                availableAspectRatios={[null, '1:1', '4:3', '16:9']}
              />
            </Segment>
          </Col>
          <Col m="6" s="12">
            <Segment raised>
              <Header size="xs">{__('Favicon')}</Header>
              <PhotoUpload
                formName={FORM_NAME}
                uploadFieldName="faviconUpload"
                deleteFieldName="faviconDelete"
                src={this.props.profileFaviconSrc}
                availableAspectRatios={['1:1']}
              />
            </Segment>
          </Col>
        </Row>
        <Segment raised>
          <Header size="xs">{__('Téma')}</Header>
          <ThemeForm />
        </Segment>
        <Segment raised>
          <Header size="xs">{__('Súhlas s GDPR')}</Header>
          <GDPRForm />
        </Segment>
        <Segment raised>
          <Header size="xs">{__('Nastavenie cookies lišty')}</Header>
          <CookiesForm />
        </Segment>
      </Form>
    );
  }
}

CommonForm.propTypes = {
  profileLogoSrc: PropTypes.string,
  profileFaviconSrc: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

CommonForm.defaultProps = {
  profileLogoSrc: null,
  profileFaviconSrc: null,
};

const connected = reduxForm({
  enableReinitialize: true,
  form: FORM_NAME,
})(CommonForm);

export default connect()(connected);
