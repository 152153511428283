import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import NotFound from '@sportnet/ui/NotFound';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import useQuery from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import {
  getListTotal,
  hasCommitFailed,
  isCommiting,
} from '@sportnet/redux-list';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { initializeOrSetListParams } from '../App/actions';
import { aclSelector } from '../App/selectors';
import { loadPPO as loadPPOAction } from '../PPO/List/actions';
import { loadUsersList, getUsersListParams2Filter } from './actions';
import { USERS_LIST, USERS_LIST_QUERY_HOC_CONFIG } from './constants';
import { matchDefinition, userDefinition } from './definitions';
import Filter from './Filter';
import MembershipProlongModal from './MembershipProlongModal';
import { usersCurrentListSelector } from './selectors';
import BasicTable from '@sportnet/ui/BasicTable';
import moment from 'moment';
import { myAndParentPPOsCodelistSelector } from '../App/selectors';
import Api from '../../Api';
import Image from '@sportnet/ui/Image';
import { UserWithPhoto } from './commonStyles';
import UsersListLoader from './UsersListLoader';

const List = ({
  history,
  location: { search, pathname },
  match: {
    params: { appspace },
  },
  hasLoadFailed,
  setListParams,
  total,
  users,
  isLoading,
  loadUsers,
  loadPPO,
  membershipToPPOCodelist,
}) => {
  const [selected, setSelected] = React.useState({});
  const [membershipProlongUsers, setMembershipProlongUsers] =
    React.useState(null);

  const { query, setQuery } = useQuery(
    search,
    serializedQuery => history.push(`${pathname}${serializedQuery}`),
    USERS_LIST_QUERY_HOC_CONFIG,
  );

  React.useEffect(() => {
    setListParams({
      listName: USERS_LIST,
      params: query,
    }).then(() => loadUsers());
  }, [
    loadUsers,
    setListParams,
    query, // reload items when query changes
  ]);

  // load active PPO
  React.useEffect(() => {
    loadPPO(appspace);
  }, [appspace, loadPPO]);

  // unselect all if filter changes
  React.useEffect(() => {
    setSelected({});
  }, [query]);

  const handleClickUser = React.useCallback(
    user => {
      history.push(`/admin/${appspace}/users/${user._id}/profile`);
    },
    [history, appspace],
  );

  const [sorter, setSorter] = React.useState(query.sorter);
  const parseQuerySorter = querySorter => {
    if (querySorter.startsWith('-')) {
      return { col: querySorter.substring(1), dir: 'desc' };
    }
    return { col: querySorter, dir: 'asc' };
  };
  const handleSort = async (col, dir) => {
    let newSorter = `${col.id}`;
    if (dir === 'desc') {
      newSorter = `-${col.id}`;
    }
    setSorter(newSorter);
    setQuery({ sorter: newSorter });
  };

  const shouldShowLoader = isLoading;
  const anyRowSelected = Object.keys(selected).some(id => selected[id]);
  const currentSorter = parseQuerySorter(sorter);

  const [isExportingUsers, setIsExportingUsers] = React.useState(false);
  const startExportingUsers = async () => {
    setIsExportingUsers(true);

    try {
      const { url } = await Api.crmGetUsers(appspace, {
        ...getUsersListParams2Filter({
          ...query,
          offset: 0,
          limit: 0,
          with: 'regnrs,membership,addresses,sport_orgs,sport_expert_orgs,medical_examinations,custom',
        }),
        output: 'xlsx',
      });
      setIsExportingUsers(false);
      window.location = url;
    } catch (e) {
      alert(`Pri exportovaní nastala chyba ${e}`);
    } finally {
      setIsExportingUsers(false);
    }
  };

  return (
    <Layout
      topFixed={<Filter query={query} setQuery={setQuery} />}
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={query.limit}
              total={total || 0}
              onChangeOffset={e => {
                setQuery({
                  offset: e,
                });
              }}
            />
          </ContextBarItem>
          <ContextBarItem>
            {total > 0 && (
              <Button
                icon="file-download"
                loading={isExportingUsers}
                disabled={isExportingUsers}
                title={__(
                  'Exportovať všetkých vyhľadaných používateľov do excelu',
                )}
                onClick={startExportingUsers}
              >
                {__(
                  `Exportovať ${total} ${
                    total === 1 ? 'osobu' : total < 5 ? 'osoby' : 'osôb'
                  }`,
                )}
              </Button>
            )}
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              basic
              primary
              title={anyRowSelected ? '' : __('Najprv označte používateľov')}
              disabled={!anyRowSelected}
              onClick={() => {
                setMembershipProlongUsers(users.filter(u => selected[u._id]));
              }}
            >
              {__('Predĺžiť členstvo')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__('Fyzické osoby')} />
      <Segment noBottomGutter>
        <Segment noBottomGutter raised>
          {shouldShowLoader ? (
            <UsersListLoader />
          ) : hasLoadFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={loadUsers}
            />
          ) : (
            <BasicTable
              columns={[
                {
                  id: 'person',
                  header: __('Meno a priezvisko'),
                  sortable: true,
                  sort:
                    currentSorter.col === 'person'
                      ? currentSorter.dir
                      : undefined,
                },
                {
                  id: 'regnr',
                  header: __('Registračné číslo'),
                  sortable: false,
                },
                {
                  id: '_id',
                  header: __('Sportnet ID'),
                  sortable: true,
                  sort:
                    currentSorter.col === '_id' ? currentSorter.dir : undefined,
                },
                {
                  id: 'birthdate',
                  header: __('Dátum narodenia'),
                  sortable: true,
                  sort:
                    currentSorter.col === 'birthdate'
                      ? currentSorter.dir
                      : undefined,
                },
                {
                  id: 'ppoRelation',
                  header: __('Vzťah'),
                  sortable: false,
                },
              ]}
              rows={users}
              onClickRow={handleClickUser}
              renderRow={item => [
                <UserWithPhoto>
                  <Image
                    src={item.formalPhoto ? item.formalPhoto.public_url : null}
                    width={35}
                    height={35}
                    style={{
                      opacity:
                        item.formalPhoto && !item.formalPhoto?.public ? 0.2 : 1,
                    }}
                  />
                  <div>
                    {item.name || ''} <strong>{item.surname || ''}</strong>
                  </div>
                </UserWithPhoto>,
                <SmarttagTags
                  items={membershipToPPOCodelist
                    .map(({ label: ppoName, value: ppoId }) => {
                      const regnr4PPO = (item.regnrs || []).find(
                        ({ org_profile_id }) => org_profile_id === ppoId,
                      );
                      if (regnr4PPO) {
                        return {
                          key: ppoId,
                          title: ppoName,
                          values: [{ key: regnr4PPO.regnr }],
                        };
                      }
                      return null;
                    })
                    .filter(a => a)}
                />,
                item._id,
                moment(item.birthdate).format('DD.MM.YYYY'),
                item.ppoRelation && (
                  <div style={{ fontSize: '80%' }}>
                    {item.ppoRelation.flags.join(', ')}
                  </div>
                ),
              ]}
              rowKey="_id"
              onSelect={setSelected}
              selected={selected}
              onSort={handleSort}
            />
          )}
        </Segment>
      </Segment>
      {!!membershipProlongUsers && (
        <MembershipProlongModal
          isOpen
          handleClose={() => {
            setMembershipProlongUsers(null);
          }}
          users={membershipProlongUsers}
          onSuccess={loadUsers}
          onSuccessClose={() => {
            setSelected({});
          }}
          membershipToPPO={query.membershipToPPO}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    users: usersCurrentListSelector(USERS_LIST)(state),
    total: getListTotal(USERS_LIST)(state) || 0,
    isLoading: isCommiting(USERS_LIST)(state) || false,
    hasLoadFailed: hasCommitFailed(USERS_LIST)(state) || false,
    acl: aclSelector(state),
    membershipToPPOCodelist: myAndParentPPOsCodelistSelector(state),
  };
};

const mapDispatchToProps = {
  loadUsers: loadUsersList,
  setListParams: initializeOrSetListParams,
  loadPPO: loadPPOAction,
};

List.propTypes = {
  match: matchDefinition.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(userDefinition).isRequired,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  acl: PropTypes.shape({}),
  loadUsers: PropTypes.func.isRequired,
  setListParams: PropTypes.func.isRequired,
  loadPPO: PropTypes.func.isRequired,
};

List.defaultProps = {
  acl: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
