import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatUserName, getProp } from '@sportnet/utilities';
import { __ } from '../../../utilities';
import { appspaceSelector } from '../../App/selectors';
import { matchDefinition, userDefinition } from '../definitions';
import { getActiveUserSelector } from '../selectors';
import { rem } from 'polished';

class FODetailWrapper extends PureComponent {
  render() {
    const {
      activeTab,
      user,
      isFetching,
      selectedOrg,
      match: {
        params: { sportnetId, appspace },
      },
    } = this.props;
    return (
      <div>
        {user && (
          <AppContext
            backUrl={`/admin/${selectedOrg.app_space}/users`}
            title={
              <>
                {formatUserName(user)}
                {user.ppoRelation && (
                  <span
                    style={{
                      fontSize: '70%',
                      fontWeight: 'normal',
                      marginLeft: rem(10),
                    }}
                  >
                    ({user.ppoRelation.flags.join(', ')})
                  </span>
                )}
              </>
            }
            breadcrumbs={[
              {
                url: `/admin/${selectedOrg.app_space}/users`,
                name: __('Fyzické osoby'),
              },
              { name: __('Detail osoby') },
            ]}
          />
        )}
        <Tabber
          active={activeTab}
          loading={isFetching}
          onClickItem={itemName => {
            switch (itemName) {
              case __('Profil'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/profile`,
                );
                break;
              case __('Fotky'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/photos`,
                );
                break;
              case __('Bio'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/bios`,
                );
                break;
              case __('Adresy'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/addresses`,
                );
                break;
              case __('Bankové účty'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/bank-accounts`,
                );
                break;
              case __('Príslušnosti'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/affiliations`,
                );
                break;
              case __('Ocenenia'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/awards`,
                );
                break;
              case __('Kvalifikácie / Licencie'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/licences`,
                );
                break;
              case __('Dokumenty'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/documents`,
                );
                break;
              case __('Ostatné'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/custom`,
                );
                break;
              case __('Členstvá'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/membership`,
                );
                break;
              case __('Transfery'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/transfers`,
                );
                break;
              case __('Registračné čísla'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/regnrs`,
                );
                break;
              case __('Lekárske prehliadky'):
                this.props.history.push(
                  `/admin/${appspace}/users/${sportnetId}/medical-examinations`,
                );
                break;
              default:
                break;
            }
          }}
          items={[
            __('Profil'),
            __('Adresy'),
            __('Bankové účty'),
            __('Fotky'),
            __('Bio'),
            __('Transfery'),
            ...(getProp(user, ['ppoRelation', 'flags'], []).filter(f =>
              ['sport_orgs', 'sport_expert_orgs'].includes(f),
            ).length
              ? [
                  __('Príslušnosti'),
                  __('Kvalifikácie / Licencie'),
                  __('Dokumenty'),
                  __('Členstvá'),
                  __('Registračné čísla'),
                  __('Lekárske prehliadky'),
                  __('Ocenenia'),
                ]
              : []),
            __('Ostatné'),
          ]}
        >
          <Segment>{this.props.children}</Segment>
        </Tabber>
      </div>
    );
  }
}

FODetailWrapper.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string.isRequired,
  user: userDefinition,
  isFetching: PropTypes.bool,
  match: matchDefinition.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  selectedOrg: PropTypes.shape({
    app_id: PropTypes.string,
    app_space: PropTypes.string,
    display_name: PropTypes.string,
    org_profile: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

FODetailWrapper.defaultProps = {
  children: <div />,
  isFetching: false,
};

const mapStateToProps = state => ({
  user: getActiveUserSelector(state),
  selectedOrg: appspaceSelector(state),
});

export default connect(mapStateToProps)(withRouter(FODetailWrapper));
