import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import React from 'react';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import Wrapper from '../index';
import { Faded } from '../../detail';
import Api from '../../../../Api';
import { format } from 'date-fns';
import Icon from '@sportnet/ui/Icon';
import { css, styled } from 'styled-components';
import { rem } from 'polished';
import config from '../../../../config';

const IconWrapper = styled.div`
  background-color: ${({ theme, $success, $danger }) => {
    if ($success) return theme.color.success;
    if ($danger) return theme.color.danger;
    return '#969696';
  }};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(16)};
  width: ${rem(16)};
`;

const StyledTr = styled(Tr)`
  ${({ $rejected }) =>
    !!$rejected &&
    css`
      > td {
        color: gray;
        text-decoration: line-through;
      }
    `}
`;

const TransfersList = ({
  match: {
    params: { appspace, sportnetId },
  },
}) => {
  const [transfers, setTransfers] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    setIsFetching(true);
    Api.crmUserTransfers(sportnetId, appspace)
      .then(({ items }) => {
        setTransfers(items);
      })
      .catch(e => {
        console.error(e);
        setTransfers([]);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [appspace, sportnetId]);

  return (
    <Layout>
      <Wrapper activeTab={__('Transfery')} isFetching={isFetching}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Stav')}</Th>
                <Th>{__('Typ')}</Th>
                <Th>{__('Klub')}</Th>
                <Th>{__('Súťaž')}</Th>
                <Th>{__('Veková kategória')}</Th>
                <Th>{__('Sezóna')}</Th>
                <Th>{__('Dátum (od - do)')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transfers.map(item => {
                const transferType =
                  config.TRANSFER_TYPE[item.transferType] || item.transferType;

                return (
                  <StyledTr
                    key={item._id}
                    $rejected={item.transferStatus === 'REJECTED'}
                  >
                    <Td>
                      {item.transferStatus === 'APPROVED' ? (
                        <IconWrapper title="Schválený" $success>
                          <Icon name="check" color="white" size={14} />
                        </IconWrapper>
                      ) : item.transferStatus === 'REJECTED' ? (
                        <IconWrapper title="Zamietnutý" $danger>
                          <Icon name="close" color="white" size={14} />
                        </IconWrapper>
                      ) : (
                        item.transferStatus
                      )}
                    </Td>
                    <Td>
                      {transferType.title || transferType}
                      {!!transferType.subtitle && (
                        <>
                          <br />
                          <Faded>{transferType.subtitle}</Faded>
                        </>
                      )}
                    </Td>
                    <Td>{`${item.sourceClubName} → ${item.destinationClubName}`}</Td>
                    <Td>
                      <span>
                        {[
                          item.destinationCompetitionPPO,
                          item.destinationCompetitionName,
                        ]
                          .filter(e => !!e)
                          .join(':')}
                      </span>
                      {!!item.destinationCompetitionIssfID && (
                        <>
                          <br />
                          <Faded>
                            ISSF ID: <b>{item.destinationCompetitionIssfID}</b>
                          </Faded>
                        </>
                      )}
                    </Td>

                    <Td>{item.personSeasonAgeLevelU}</Td>
                    <Td>{item.seasonName}</Td>
                    <Td>
                      {format(new Date(item.dateFrom), 'dd.MM.yyyy')}
                      {item.dateTo
                        ? ` - ${format(new Date(item.dateTo), 'dd.MM.yyyy')}`
                        : ' - '}
                    </Td>
                  </StyledTr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
    </Layout>
  );
};

export default TransfersList;
